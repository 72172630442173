import React from 'react';
import { CircleButton } from '@hiyllo/ux/circle-button';

import * as CreateAPIKeyBP from '../../blueprints/admin/create-api-key';
import { seamlessAuth, seamlessClient } from '../../seamless';
import { PageContainer } from '../ux/page-container';
import { Header } from '../ux/typography';

export const Home = (): JSX.Element => {
  const authState = seamlessAuth.useAuthState();
  const createAPIKeyMutation = seamlessClient.useSeamlessMutation<CreateAPIKeyBP.Plug>(CreateAPIKeyBP);

  const createToken = React.useCallback((): void => {
    void createAPIKeyMutation.call({ label: `dev/${Date.now().toString()}` }).then(({ token }) => {
      alert(token);
    });
  }, []);

  return (
    <PageContainer>
      <Header>
        Hi, {authState?.userId}
      </Header>
      <CircleButton
        onClick={createToken}
        isLoading={createAPIKeyMutation.isLoading}
      />
    </PageContainer>
  );
};
