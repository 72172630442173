/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
  label: string;
};
export type ResponseType = {
  token: string;
};
export const Endpoint = 'admin/create-api-key';
export const Method = 'POST';

export interface Plug {
  params: ParamsType
  response: ResponseType
  method: typeof Method
  endpoint: typeof Endpoint
};export const paramsSchema = {"type":"object","properties":{"label":{"type":"string"}},"required":["label"],"$schema":"http://json-schema.org/draft-07/schema#"}