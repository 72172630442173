import React from 'react';

export const PageContainer = (props: React.PropsWithChildren): JSX.Element => {
  return (
    <div style={{
      backgroundColor: '#f5f5f5',
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    }}>
      <div style={{
        padding: 20,
        maxWidth: 1920,
        width: '100%',
        backgroundColor: '#fff'
      }}>
        {props.children}
      </div>
    </div>
  );
};
